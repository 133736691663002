.App {
  text-align: center;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.gm-style .gm-style-iw-t {
  bottom: 20px !important;
}

.gm-style .gm-style-iw-d {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.gm-style .gm-style-iw-c {
  padding: 0;
  border-radius: 0;
}
.gm-ui-hover-effect {
  background-color: #607D8B !important;
  border-radius: 100%;
  top: 6px !important;
  right: 6px !important;
}

.info-window {
  display: grid;
}

.info-window .heading {
  padding: 10px;
  background: #37474F;
  color: #fff;
  font-size: 1.2rem;
  position: relative;
}

.info-window .heading .close {
  background: #a8b8c0;
  height: 24px;
  width: 24px;
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 20px;
  cursor: pointer;
}

.info-window .body {
  padding: 10px;
  min-width: 250px;
  background: #fff
}

.flex {
  display: flex;
}
.flex .flex-cell {
  flex: 1;
  padding: 10px;
}

.flex .flex-cell h4 {
  margin: 0;
}

.percentage {
  position: relative;
  border: 1px solid #e7ebec;
  border-radius: 40px;
  overflow: hidden;
  color: #37474F;
  font-size: 2em;
  padding: 10px;
}

.bar {
  position: absolute;
  left: 0;
  z-index: 0;
  height: 100%;
  background: #607d8b8a;
  top: 0;
}


.circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: inherit;
    border: 2px solid inherit;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    padding: 10px;
    color: #fff;
    font-size: 0.8rem;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}


.pulse {
  position: absolute;
  left: 0;
  top:0;
  width: 100%;
  height: 100%;
  background: inherit;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  text-align: center;
  line-height: 100%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* animation: animate 2s linear infinite; */
}
@keyframes animate {
  0% {
      box-shadow: 0 0 0 0 rgba(255, 0, 64, 0.7), 0 0 0 0 rgba(255, 0, 64, 0.7);
  }
  40% {
      box-shadow: 0 0 0 50px rgba(255, 0, 64, 0), 0 0 0 0 rgba(255, 0, 64, 0.7);
  }
  80% {
      box-shadow: 0 0 0 50px rgba(255, 0, 64, 0), 0 0 0 30px rgba(255, 0, 64, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(255, 0, 64, 0), 0 0 0 30px rgba(255, 0, 64, 0);
  }
}

.circle-body {
  z-index: 10;
}